const environment =
  process.env.CIRCLE_BRANCH_OVERRIDE ||
  process.env.CIRCLE_BRANCH ||
  process.env.PLATFORM_ENVIRONMENT_TYPE ||
  process.env.NODE_ENV;

const isLocalBuild =
  process.env.GATSBY_LOCAL_BUILD === `true` ||
  process.env.GATSBY_LOCAL_BUILD === true;

const pathPrefix =
  isLocalBuild ||
  process.env.ROOT_MODE === true ||
  process.env.ROOT_MODE === `true` ||
  process.env.GATSBY_ROOT_MODE === true ||
  process.env.GATSBY_ROOT_MODE === `true`
    ? null
    : `/${process.env.GATSBY_CURRENT_LOCALE}`;

const locale = process.env.GATSBY_CURRENT_LOCALE;

const apiLocale = isLocalBuild ? `all` : process.env.GATSBY_CURRENT_LOCALE;

if (
  isLocalBuild === false &&
  (typeof locale === `undefined` || locale === null)
) {
  throw new Error(
    `Error: Site needs a locale set such as 'en' as an environment variable.`
  );
}

const localeRoot =
  process.env.ROOT_MODE === true ||
  process.env.ROOT_MODE === `true` ||
  process.env.GATSBY_ROOT_MODE === true ||
  process.env.GATSBY_ROOT_MODE === `true`
    ? null
    : locale;

module.exports = {
  environment,
  environmentType:
    process.env.PLATFORM_ENVIRONMENT_TYPE ||
    process.env.GATSBY_ENVIRONMENT_TYPE ||
    null,
  cmsUrl: process.env.GATSBY_CMS_URL,
  localOpenSearchUrl:
    process.env.GATSBY_LOCAL_OPENSEARCH_URL || process.env.GATSBY_CMS_URL,
  siteUrl: process.env.GATSBY_SITE_URL,
  strapiToken: process.env.STRAPI_TOKEN,
  isLocalBuild,
  pathPrefix,
  apiLocale,
  localeRoot,
  locale
};
