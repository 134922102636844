import React, { useCallback, useState, useEffect } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import Wrapper from '@components/atoms/Wrapper';
import { ArrowRightIcon } from '@components/atoms/icons';
import FormFieldSelect from '@components/molecules/FormFieldSelect';
import FormFieldText from '@components/molecules/FormFieldText';
import FormFieldTextarea from '@components/molecules/FormFieldTextarea';
import FormFieldNumber from '@components/molecules/FormFieldNumber';
import FormFieldEmail from '@components/molecules/FormFieldEmail';
import FormFieldDate from '@components/molecules/FormFieldDate';
import FormFieldFile from '@components/molecules/FormFieldFile';
import FormFieldCheckbox from '@components/molecules/FormFieldCheckbox';
import FormFieldRadio from '@components/molecules/FormFieldRadio';
import FormStatus from '@components/organisms/FormStatus';
import { localeRoot } from '@helpers/environment';
import { appendIfValid } from '@helpers/linkResolver';
import { useApplyForm } from '../../hooks/use-apply-form';
import {
  postForm,
  mapFieldGroups,
  blobToBase64
} from '../../helpers/formHelpers';

const FieldComponents = {
  text: FormFieldText,
  textarea: FormFieldTextarea,
  selection: FormFieldSelect,
  number: FormFieldNumber,
  email: FormFieldEmail,
  date: FormFieldDate,
  file: FormFieldFile,
  checkbox: FormFieldCheckbox,
  radio: FormFieldRadio
};

const filterElements = (elements) => {
  return Array.from(elements).filter((element) => {
    if (
      element.type !== `submit` &&
      (element.id === null || element.id?.length === 0)
    ) {
      throw new Error(`ID could not be found on element:`, element);
    }

    return (
      element.id !== null && element.id.length > 0 && element.type !== `submit`
    );
  });
};

const mapValues = (elements) => {
  return elements.map((currentElement) => {
    const { id, name, value, type } = currentElement;

    if (type === `radio` && currentElement.checked === false) {
      return {};
    }

    if (type === `file` && currentElement.files.length > 0) {
      const [file] = currentElement.files;

      return {
        id,
        name,
        type,
        value: file.name,
        optional: {
          file
        }
      };
    }
    return { id, name, type, value, optional: {} };
  });
};

const ApplyForm = ({ jobReference, jobTitle, lineManager }) => {
  const {
    strapiApplyForm: {
      formId,
      title,
      defaultPlaceholder,
      defaultErrorMessage,
      fromAddress,
      toAddress,
      responseText,
      sendingMessageText,
      submitButtonText,
      fieldGroups
    }
  } = useApplyForm();

  const parsedFieldGroups = mapFieldGroups(fieldGroups);

  const [formValid, setFormValid] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formDidError, setFormDidError] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const handleOnFieldRendered = useCallback(({ fieldId, required }) => {
    if (required === true) {
      setInvalidFields((prev) => [...prev, fieldId]);
    }
  }, []);

  const handleOnFieldChange = useCallback(
    ({ fieldId, isValid }) => {
      const filtered = invalidFields.filter(
        (invalidFieldId) => invalidFieldId !== fieldId
      );

      if (isValid === false) {
        setInvalidFields([...filtered, fieldId]);
      } else {
        setInvalidFields(filtered);
      }
    },
    [invalidFields]
  );

  const handleOnFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const filteredElements = filterElements(event.target.elements);
      const mappedValues = mapValues(filteredElements);
      const mapped =
        mappedValues.find((value) => value?.optional?.file) || null;
      const b64 =
        mapped && mapped.optional.file !== null
          ? await blobToBase64(mapped.optional.file)
          : null;

      setSubmitAttempted(true);
      setFormDidError(false);

      if (formValid === true) {
        setSubmitAttempted(false);
        setSubmitInProgress(true);

        const { success, data: returnedFormData } = await postForm(
          `/${appendIfValid(localeRoot, `/`)}api/post-apply/`,
          {
            formId,
            toAddress,
            fromAddress,
            values: mappedValues,
            job: { jobReference, jobTitle, lineManager },
            attachment:
              b64 !== null
                ? { blob: b64, filename: mapped?.optional.file.name }
                : null
          }
        );

        setSubmitInProgress(false);

        if (success === true) {
          setSubmitSuccess(true);
          return;
        }

        console.error(returnedFormData);
        setFormDidError(true);
      } else if (typeof window !== `undefined` && invalidFields.length > 0) {
        const [firstInvalidField] = invalidFields;
        const resolvedField = document.getElementById(firstInvalidField);

        if (resolvedField) {
          resolvedField.scrollIntoView({
            behavior: `smooth`,
            block: `center`,
            inline: `nearest`
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      formId,
      formValid,
      fromAddress,
      invalidFields,
      jobReference,
      jobTitle,
      lineManager,
      toAddress
    ]
  );

  useEffect(() => {
    setFormValid(invalidFields.length === 0);
  }, [invalidFields]);

  return (
    <Wrapper maxWidth="64rem">
      <Flex
        justifyContent="center"
        alignContent="center"
        py={{ base: 8, md: 12 }}
        px={{ base: 8, md: 12 }}
        bg="secondary.pale-blue"
        borderRadius="sm">
        {submitInProgress === true ||
        submitSuccess === true ||
        formDidError === true ? (
          <FormStatus
            responseText={responseText}
            sendingMessageText={sendingMessageText}
            formDidError={formDidError}
            submitInProgress={submitInProgress}
            submitSuccess={submitSuccess}
            hideButton
          />
        ) : (
          <Flex maxWidth={{ base: `none`, md: `25rem` }} flexDirection="column">
            <Heading>{title}</Heading>
            <Box
              id="apply-form"
              as="form"
              onSubmit={handleOnFormSubmit}
              noValidate>
              {parsedFieldGroups?.length > 0 &&
                parsedFieldGroups.map(({ id, label, fields }) => {
                  return (
                    <Box
                      w="100%"
                      key={`apply-form-field-group-${label}-${id}}`}
                      mb={{ base: `0`, md: `6` }}>
                      <Heading
                        as="p"
                        size="md"
                        color="primary.dark-blue"
                        mb="2">
                        {label}
                      </Heading>
                      {fields?.length > 0 &&
                        fields.map(
                          ({
                            id: fieldId,
                            label: fieldLabel,
                            fieldType,
                            errorMessage,
                            required,
                            placeholder
                          }) => {
                            if (fieldType === null) {
                              return (
                                <div key={`dynamic-zone-component-${fieldId}`}>
                                  Field type missing for {fieldId}.
                                </div>
                              );
                            }

                            const FieldComponent = fieldType.type
                              ? FieldComponents[String(fieldType.type)]
                              : null;

                            if (
                              typeof FieldComponent === `undefined` ||
                              FieldComponent === null
                            ) {
                              return (
                                <div
                                  key={`dynamic-zone-component-${fieldType.type}-${fieldId}`}>
                                  Component <b>{fieldType.type}</b> not found on
                                  {` `}
                                  {fieldId}.
                                </div>
                              );
                            }

                            return (
                              <FieldComponent
                                key={`apply-form-field-component-${fieldType.type}-${fieldId}`}
                                fieldId={fieldId}
                                submitAttempted={submitAttempted}
                                defaultPlaceholder={defaultPlaceholder}
                                overridePlaceholder={placeholder}
                                required={required}
                                errorMessage={
                                  errorMessage || defaultErrorMessage
                                }
                                fieldLabel={fieldLabel}
                                fieldValue={fieldType.fieldValue}
                                onReady={handleOnFieldRendered}
                                onChange={handleOnFieldChange}
                              />
                            );
                          }
                        )}
                    </Box>
                  );
                })}
              <Button
                type="submit"
                disabled={formValid === false || submitInProgress === true}
                rightIcon={<ArrowRightIcon />}>
                {submitButtonText}
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
    </Wrapper>
  );
};

export default ApplyForm;
