import React from 'react';
import { HStack } from '@chakra-ui/react';

const SliderPagination = ({ color = `primary.dark-blue` }) => (
  <HStack
    className="pagination"
    spacing="0"
    textAlign="center"
    whiteSpace="nowrap"
    position="relative"
    font-size="0"
    sx={{
      '.swiper-pagination-bullet': {
        marginLeft: 1.5,
        marginRight: 1.5,
        position: `relative`,
        transform: `scale(0)`,
        width: 3,
        height: 3,
        minWidth: `12px`,
        minHeight: `12px`,
        background: color,
        border: `2px`,
        borderColor: color,
        borderRadius: `100%`,
        cursor: `pointer`,
        transition: `.3s`
      },
      // The current actively clicked bullet
      '.swiper-pagination-bullet-active': {
        background: `transparent`,
        transform: `scale(1)`
      },
      // The current bullets active in the set
      '.swiper-pagination-bullet-active-main': {
        transform: `scale(1)`
      },
      // The bullet(s) previous to the set
      '.swiper-pagination-bullet-active-prev': {
        transform: `scale(1)`
      },
      '.swiper-pagination-bullet-active-prev-prev': {
        transform: `scale(.66)`
      },
      // The bullet(s) ahead of the set
      '.swiper-pagination-bullet-active-next': {
        transform: `scale(1)`
      },
      '.swiper-pagination-bullet-active-next-next': {
        transform: `scale(.66)`
      }
    }}
  />
);

export default SliderPagination;